.dark_tabel_head{
    background: #c4c4c4 !important ;
}
.pointer{
    cursor: pointer;
}
.centered-cell {
    text-align: center;
    vertical-align: middle;
  }
  .Activ_btn_action{
    transition: all .5s;
  }
  .Activ_btn_action:hover{
    transform: scale3d(1.5, 1.5, 1.5)

}