.dark_btn{
    background: #2a2f38;
    font-size: 22px;
    color: #fff;
}
.dark_btn:hover{
    background: #22252b !important;
    font-size: 22px;
    color: #fff !important;
}
.red_btn{
    background: #eb3d34;
    font-size: 22px;
    color: #fff;
}
.red_btn:hover{
    background: #df382f !important;
    font-size: 22px;
    color: #fff !important;
}