.form-login{
    display: flex
}
.login-page{
    height: 95vh;

}

.form-login input.form-control {
    height: 46px;
    border-color: #ced4da;
}
.form-login form {
    box-shadow: 1px 1px 24px 0px #f0f0f0;
    padding: 44px;
    border-radius: 10px;

}
@media screen and (max-width:768px) {
    .form-login form {
       width: 100% !important;
    }
}
.form-login form button{
    height: 50px;

}
