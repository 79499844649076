.spinner_container{
width: 100%;
height: 100%;
background-color: #fff;
display: flex;
align-items: center;
justify-content: center;

}
.spinner_container div{
    align-items: center;
    justify-content: center;
}