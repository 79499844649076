
.form_filter_chart{
    display: flex;
 justify-content: space-between;
 align-items: center;
}
.inser_tabel {
   
    min-width: 500px;
    min-height: 15vh;
}
.item_1_m{
    padding-left: 5px;
}
.item_2_m{
    padding-left: 10px;
}
.item_3_m{
    padding-left: 15px;
}
.item_4_m{
    padding-left: 20px;
}
.item_5_m{
    padding-left: 30px;
}
.item_1_m{
    padding-left: 5px;
}

.b_button_item{
    border-bottom: 2px solid #bcb9b9;
    /* padding-right: 1px;
    padding-left: 1px; */
    
}
.main_back_page
{
    min-height: 68vh;
}
.form_filter_chart .form_filter .donate-now li{
min-width: 70px;
font-size: 14px;
}
.inser_tabel .row:hover   {
 color: #000000 ;
 font-weight: 400;
 cursor: pointer;
}
 
.inser_tabel .row
{
    width: 100%;
    margin: auto;
}
 
.inser_tabel 
{
    color:#2B2F38
}
.hr_btn_div
{
    width: 4%;
    height: 29px;
    background: #bcb9b9;
    margin-top: -6px;
}
.inser_tabel  div:hover .ArrowToggleIcon{
    visibility:visible
}
.ArrowToggleIcon{
visibility: hidden;
}
.Max_h_tabel {
    max-height: 73vh;
}
.Max_h_tabel_full{
    max-height: 90vh;

}
.NotWrap_Overvew_auto{
    white-space: nowrap;
    overflow: auto;
}
.text_red{
    color: #dc3545;
}

.hover_parant:hover{
color: #000 ;
font-weight: 500;
}
.Full_screen
{
    z-index: 19;
    position: sticky;
    margin-bottom: 12px;
}

.full_screen_Tabel{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background: #f8f9fa;
    z-index: 104;
    top: 0;
    left: 0;
}
.hight_div_charts{
    height: 100vh;
}