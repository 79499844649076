.form_filter
{
    overflow: hidden;
}
.form_filter .donate-now {
    list-style-type: none;
    margin:0px;
    margin-top:5px;
    padding: 0;
  }
  
  .form_filter .donate-now li {
    margin: 0 3px 0 0;
    width: 42px;
    height: 34px;
    position: relative;
    display: inline-flex;
    background: #f6f6f6;
  }
  
  .form_filter .donate-now label,
  .form_filter .donate-now input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .form_filter .donate-now input[type="radio"] {
    opacity: 0.01;
    z-index: 100;
  }
  
  .form_filter .donate-now input[type="radio"]:checked+label,
  .form_filter .Checked+label {
    background: #EB3D34;
    color: #ffff;
  }
  .form_filter .donate-now input[type="radio"]:hover+label
    {
    background: #EB3D34;
    color: #ffff;
    transition: .3s;
  }
  .form_filter .donate-now label {
    padding: 5px;
    border: 1px solid #CCC;
    cursor: pointer;
    z-index: 90;
  }
  
  .form_filter .donate-now label:hover {
    background: #DDD;
  }
  .mounth-div:before {
    content: " ";
    width: 3px;
    height: 79px;
    background: #ea3e36;
    display: flex;
    position: absolute;
    right: 5px;
    top: 20px;
}
.mounth-div::after {
    content: " ";
    width: 3px;
    height: 79px;
    background: #ea3e36;
    display: flex;
    position: absolute;
    left: 5px;
    top: 20px;
}
.form_filter .input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

.form_filter input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.form_filter .date_input_label
{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  border-style: solid;
    border-width: 7px 2px 1px 2px;
    border-color: #dc3545c9;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
 
}
 
.parned_input::after {
  content: " ";
  width: 5px;
  height: 13px;
  background: #e3606c;
  position: absolute;
  top: -7px;
  right: 18%;
}
.parned_input::before {
  content: " ";
  width: 5px;
  height: 13px;
  background: #e3606c;
  position: absolute;
  top: -7px;
  right: 72%;
}
#date_input
 {
  width: 44px;
    height: 45px;
 }
@media screen and (max-width:575px) {
    .mounth-div:before {
    content: " ";
    width: 50%;
    height: 1px;
    background: #ea3e36;
    display: flex;
    position: absolute;
    top: 10px;
    left: 25%;
    }
    .mounth-div::after {
        content: " ";
        width: 50%;
        height: 1px;
        background: #ea3e36;
        display: flex;
        position: absolute;
        left: 25%;
        top: 111px;
    }
}