.PageNotFound{
    min-height: 82vh;
   

}
 
 
  .error-message::before {
    content: "404";
     
    font-size: 150px;
    color: rgba(255, 255, 255, 0.2);
    text-shadow: 2px 2px black;
    transform: rotateY(45deg) translateZ(100px);
    opacity: 0.3;
  }
  .masseg_erorr
  {
    transform: rotate(30deg);
    transform-origin: top left;
    transform: rotate(30deg);
    animation: left_And_Right_router 5s alternate-reverse infinite  ;
    border-radius: 0px 36px 20px;
  }

  @keyframes left_And_Right_router {
    from {    transform: rotate(30deg);}
    to {transform: rotate(-20deg);}
  }
  .pin_icon_error
  {
    position: absolute;
    top: -23px;
    left: -1px;
    color: #eb3d34;
    font-size: 26px;
  }