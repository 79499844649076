.assidBar .nav-link {
color: #212529;
}
.assidBar .accordion-button:hover{
color: #fff ;
background: #EB3D34;
border-radius: 18px;
}
.assidBar .nav-item  a:hover {
    background: #eee;
border-radius: 18px;

}