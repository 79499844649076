.active>.page-link, .page-link.active {
    z-index: 3;
    color: #232323;
    background-color: transparent  !important;
    border: 1px solid #ddd !important;
    border-radius: 50%;
}
li.page-item.disabled{
    border: none;
    color: black;
    background-color: transparent  !important;
}
.page-item:not(:first-child) .page-link ,.page-item:first-child .page-link{
    margin-left: -1px;
    border: none;
    color: black;  
    background: transparent;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}
.disabled>.page-link, .page-link.disabled{
    border: none;
    color: black   ;
}
.active>.page-link, .page-link.active{

    background: #df2915 !important;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #fff !important;
    box-shadow: none !important;
    

}
 .test3__control {
    height: 40px !important;
    border-radius: 10px;
 }
 .page-link{
    font-size: 13px;
 }
 .pagination{
    margin: 0 !important}