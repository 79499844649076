.back_main{
    background:#FCFCFC;
}
.main_back_page{
    background-color:#F7F7F7
 }
 .titel_head_pages
{
    border-bottom: 2px solid #e83c33;
 
    padding-bottom: 10px;
}

.Light_back{
    background-color: #fff;
}

 
  ::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #808182; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6f7172; 
  }

  .pointer:hover{
    cursor: pointer;
  }