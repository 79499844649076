.dropdown-menu.show {

    margin-left: -33px;
}
nav.navbar
{
    position: sticky;
    top: 0;
    background: #ffffffe6;
    z-index: 100;
}
.navbar-brand{
    cursor: pointer;
}
.DropdownUser .dropdown-menu[data-bs-popper] li:active{
    background: unset ;
    color: unset;
}