.EmployeeProfile ul{
    list-style: none;
    line-height: 2;
}
.EmployeeProfile ul li{
    cursor: pointer;
}
.back_Personal_P{
    background:  #e83c33;
    color: #fff;
}
.action_li:hover{
    background: #ddd;

}

.EmployeeProfile .div_tabel{
    height: 340px;
}