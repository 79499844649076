 
 
.befor_item_Border::before
{
    /* border-right: 2px solid #e83c33; */
    content: " ";
    width: 3px;
    height: 90%;
    background: #ea3e36;
    display: flex;
    position: absolute;
    left: 5px;
    top: 0px;
     
 }
  
 .nav_section_chart .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
      background: none;
    color: #514f4f;
}
.nav_section_chart .nav-pills .nav-link.active::after{
    transition: .5s;
    content: " ";
    width: 73%;
    height: 2px;
    background: #ea3e36;
    position: absolute;
    bottom: 0px;
    right: 14px;
    
}
.nav_section_chart .nav-pills button{
    color: #514f4f ;

} 
