.full_screen
{
    height: 99vh;
    width: 100%;
    position: fixed;
    right: 0;
    background: #fff;
    z-index: 105;
    top: -5px;
}
.icon_full_screen
{
    margin-bottom: -25px;
    transition: .5s;
}
.icon_full_screen svg:hover
{
    transition: .5s;

    transform: scale(1.2);
}
.icon_exit_full_screen svg:hover
{
    transition: .5s;

    transform: scale(.9);
}
.icon_exit_full_screen{
    margin-bottom: -25px;
    

}
 