/* .hr_page .apexcharts-yaxis  ,.hr_page  .apexcharts-grid-borders , .hr_page .apexcharts-xaxis-tick, .hr_page  .apexcharts-grid{
    display: none;
}
*/
.apexcharts-toolbar{
    display: none !important;
} 
/* .ChartGradient .apexcharts-xaxis{
    display: none
}  */
 
.item_div{
    background-color: #FDFDFD;
}
.color-icon{
    color:#9c9a9a
}
.topRightPo{
    top: 0px;
    right: 0px;
   font-size: 25px;

}
.div_tabel{
    overflow: auto;
    /* max-height: 50vh; */
}
.div_Value_data {
    width: 130px;
    height: 130px;
    box-shadow: 1px 1px 11px 1px #ddd;
    background: #f7f7f7;
    border-radius: 50%;
    color: #ea3e36;
}
.div_number_chart
{
    color:#2A2F38;
    font-size: 52px;
}
.main_table thead {
    background-color: #9B9A99;
    color: #fff;
}
button#test-table-xls-button {
    border: none;
    background: transparent;
}