.main_table thead{
    background-color: #929292;
    color: #fff;
}
.main_table tbody{
    background-color: #eceaea;
 }
 .total_table
 {
    position: sticky;
    bottom: 0px;
    background: #eb5951;
    color: #fff;
    font-weight: 600;
 }